
/****************************************************
 mp.js

 Mucky Puddle code entry point
 *****************************************************/
window.MP = {
    Extensions: {},
    Globals: {},
    Utilities: {}
};


/****************************************************
 EXTENSIONS GO HERE!
 ****************************************************/

MP.Extensions.Forms = {
    onReady: function() {
        var that = this;

        $("[id$=custom_datepicker]").datepicker({
            language: 'en',
            multipleDates: false,
            dateFormat: "dd mm yyyy",
            classes: 'mp-custom-datepicker',
            // onSelect: function(fd, d) {
            //     $("#unavailable_dates").val(fd);
            // }
        });

        $('[data-mp-field]').on('change', e => {
            let changedElement = $(e.currentTarget);

            console.log(changedElement);
            // Every time we go through a field that has the [data-mp-field]
            // attribute, we are  going to dispatch a new event.
            // We should also set this up so everything is initialized onLoad
            let fieldChangeEvent = new CustomEvent("field:changed", {
                detail: {
                    fieldId: changedElement.attr('id'),
                    fieldValueText: changedElement.children(":selected").text(),
                    fieldValue: changedElement.val()
                }
            })
            document.dispatchEvent(fieldChangeEvent)
        });

        function handleOtherOptionFields(e){
            // Get all fields that are other
            let inputsToCheck = $('[data-mp-other]')
            // Get the id of the field that changed
            let changedFieldId = e.detail.fieldId
            // Get the value of the field that changed
            let changedFieldValue = e.detail.fieldValueText;
            inputsToCheck.each((i, element) => {
                // Get the key of the field that should change
                let showField = false;
                let keyToMatch = $(element).attr('data-mp-other-key');
                // console.log('k2match', `#${keyToMatch}`+$(`#${keyToMatch}`).val())
                if($(`#${keyToMatch}`).children(":selected").text().toLowerCase() === "other".toLowerCase()){
                    showField = true;
                }
                if(showField){
                    // $(element).find('input').removeAttr('disabled');
                    $(element).css('display', 'block');
                } else {
                    // $(element).find('input').attr('disabled', 'disabled');
                    $(element).css('display', 'none');
                }
            })
        }

        function handleRuleBasedFields(e){
            let inputsToCheck = $('[data-mp-has-rules]')
            let changedFieldId = e.detail.fieldId
            let changedFieldValue = e.detail.fieldValueText;
            inputsToCheck.each((i, elementWithRules) => {
                let rules = elementWithRules.getAttribute("data-mp-rules");
                let wrapperId = elementWithRules.getAttribute("id")
                let basepath = elementWithRules.getAttribute("data-mp-basepath")
                let jsonRules = JSON.parse(rules)
                let isAffected = false;
                let showField = false;
                jsonRules.forEach(jsonRule => {
                    if(changedFieldId === basepath + jsonRule.field){
                        isAffected = true;
                        if(jsonRule.value.includes(changedFieldValue)){
                            showField = true;
                        }
                    }
                })

                if(isAffected){
                    if(showField){
                        $(elementWithRules).find('input').removeAttr('disabled');
                        $(elementWithRules).find('select').removeAttr('disabled');
                        $(elementWithRules).find('textarea').removeAttr('disabled');
                        $(elementWithRules).find('radio').removeAttr('disabled');
                        $(elementWithRules).css('display', 'block');
                    } else {
                        $(elementWithRules).find('input').attr('disabled', 'disabled');
                        $(elementWithRules).find('select').attr('disabled', 'disabled');
                        $(elementWithRules).find('textarea').attr('disabled', 'disabled');
                        $(elementWithRules).find('radio').attr('disabled', 'disabled');
                        $(elementWithRules).css('display', 'none');
                    }
                }
            })
        }

        document.addEventListener('field:changed', e => {
            // console.log('field changed!', e.detail)
            handleRuleBasedFields(e);
            handleOtherOptionFields(e);
        });

        $('[data-mp-field]').trigger('change');


        //         $('[data-mp-field]').each((i, e) => {
        //            let fieldToSet = $(e);
        //             let fieldChangeEvent = new CustomEvent("field:changed", {
        //                 detail: {
        //                     fieldId: fieldToSet.attr('id'),
        //                     fieldValueText: fieldToSet.children(":selected").text(),
        //                     fieldValue: fieldToSet.val()
        //                 }
        //             })
        //             // console.log(fieldChangeEvent);
        //             document.dispatchEvent(fieldChangeEvent)
        //         });

        $('[data-mp-display-other]').each((i, e) => {
            otherKey=e.getAttribute('data-mp-other-key');
            sourceElement=$('[data-mp-display-id='+otherKey+']');
            sourceElementValue=sourceElement.attr("data-mp-display-value");
            // console.log("------------------");
            // console.log("other key:"+otherKey);
            // console.log("source element value:"+sourceElementValue);
            // console.log("source element value to lowercase:"+sourceElementValue.toLowerCase());
            // console.log("Other to lowercase:"+"Other".toLowerCase());
            if(sourceElementValue.toLowerCase() != "Other".toLowerCase()){
                $(e).css('display', 'none');
            } 
        });

        $('[data-mp-display-has-rules]').each((i, e) => {
            let basepath = e.getAttribute("data-mp-basepath");
            let rules = e.getAttribute("data-mp-rules");
            let parsedRules  = JSON.parse(rules);
            parsedRules.forEach(jsonRule => {
                sourceElement=$('[data-mp-display-id='+basepath+jsonRule.field+']');
                sourceElementValue=sourceElement.attr("data-mp-display-value");
                if(!jsonRule.value.includes(sourceElementValue)){
                    $(e).css('display', 'none');
                }
            })
        });

    }
}


/****************************************************
 MP EXTENSIONS INITIALISATION
 ****************************************************/
$.each(MP.Extensions, function (prop, obj) {
    if ("init" in obj)
        obj.init();
});
(function ($, window, document) {
    $(function () {
        // Call on ready for each MP extension
        $.each(MP.Extensions, function (prop, obj) {
            if ("onReady" in obj)
                obj.onReady();
        });
    })
}(window.jQuery, window, document));
